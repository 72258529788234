import {
    $id,
    $class,
    $addClass,
    $removeClass,
    $hide,
    $show,
    $on
} from '../util'
import LightControl from '../lightControl.js'
import anime from '../anime.min.js'

export default function() {

    let patternWrap = $id('patternWrap')

    let wWidth = window.innerWidth


    let lightControl = new LightControl();

    this.init = (isDiy) => {
        if (isDiy) {
            wWidth = window.innerWidth * 1.2
        } else {
            wWidth = window.innerWidth * 1.05
        }
        patternWrap.style.width = wWidth + 'px'
        patternWrap.style.height = wWidth + 'px'
        console.log('wWidth = ' + wWidth)
        lightControl.create('lightWrap');
    }

    $on('pattern-gif', (gifInd) => {
        lightControl.playGif(gifInd)
    })

    $on('pattern-name', (name) => {
        lightControl.playName(name)
    })

    $on('pattern-stop', () => {
        lightControl.stop()
    })

    $on('pattern-disable-touch', () => {
        lightControl.disableTouch();
    })

    $on('pattern-enable-touch', () => {
        lightControl.enableTouch();
    })

    $on('pattern-clear', () => {
        lightControl.clearPattern()
    })

    $on('pattern-undo', () => {
        lightControl.undo()
    })

    this.getCompressedArray = () => {
        return lightControl.getCompressedArray()
    }

    this.printCurArr = () => {
        console.log(lightControl.printCurArr())
    }

    this.getGifCompressedArray = () => {
        return lightControl.getGifCompressedArray()
    }

    this.clear = () => {

    }

    this.show = () => {
        $show(patternWrap)
            // init()
        anime({
            targets: patternWrap,
            scale: [0, 1],
            translateX: ['-50%', '-50%'],
            translateY: ['-50%', '-50%'],
            duration: 300,
            easing: 'linear'
        })
    }

    this.hide = () => {
        anime({
            targets: patternWrap,
            scale: 0,
            translateX: ['-50%', '-50%'],
            translateY: ['-50%', '-50%'],
            duration: 300,
            easing: 'linear'
        })
    }

}