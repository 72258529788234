import {
    $id,
    $class,
    $addClass,
    $removeClass,
    $hide,
    $show,
    $checkName,
    $emit
} from '../util'
import LightControl from '../lightControl.js'
import anime from '../anime.min.js'

export default function () {

    let nameWrap = $id('nameWrap')
    let inputName = $id('inputName')

    inputName.onkeydown = function (e) {
        if (e.keyCode === 13) {
            // press keyboard enter button
            previewName();
        }
    }


    inputName.addEventListener('blur', function() {
        previewName();
    })

    function previewName() {
        let n = inputName.value;
        // n = n.toLowerCase();
        if ($checkName(n)) {
            console.log('do show name: ' + n);
            $emit('pattern-name', n)
            inputName.blur();
        } else {
            inputName.value = '';
            inputName.blur();
        }
    }

    this.show = () => {
        $show(nameWrap)
        nameWrap.style.opacity = 1;
        anime({
            targets: nameWrap,
            translateY: ['200%', '0'],
            duration: 300
        })
    }

    this.hide = () => {
        anime({
            targets: nameWrap,
            translateY: '200%',
            duration: 300,
            complete: () => {
                $hide(nameWrap)
            }
        })
    }

}