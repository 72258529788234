import {
    $id,
    $checkId,
    $on,
    $emit
} from '../util.js'

import anime from '../anime.min.js'

export default function () {
    let page = $id('testPage');
    let inId = $id('inId');
    let idBtn = $id('idBtn');

    anime({
        targets: '#testBgGrid',
        translateX: ['0%', '-20%'],
        translateY: ['-20%', '0%'],
        duration: 20000,
        loop: true,
        easing: 'linear'
    });
}



// var canvas = document.getElementById("bgCvs");
// var ctx = canvas.getContext("2d");
// var cw = window.innerWidth
// var ch = window.innerHeight
// console.log(cw, ch)
// canvas.width = cw
// canvas.height = ch
// ctx.canvas.width = cw
// ctx.canvas.height = ch
// console.dir(ctx)

// var img1 = loadImage(img1Url, loadedImg);
// var img2 = loadImage(img2Url, loadedImg);

// var imagesLoaded = 0;
// var finalUrl = ''

// function loadedImg() {
//     imagesLoaded += 1;

//     if (imagesLoaded == 2) {
//         // composite now
//         ctx.fillStyle = "black";
//         ctx.fill();
//         ctx.fillRect(0, 0, cw, ch);
//         ctx.drawImage(img1, 0, 0, cw, ch);

//         ctx.globalAlpha = 0.5;
//         ctx.drawImage(img2, 0, 0, cw, ch);

//         console.log('merge finished')
//         console.log(img1)
//         finalUrl = canvas.toDataURL()
//         console.log(finalUrl.length)
//     }
// }

// function loadImage(src, onload) {
//     // http://www.thefutureoftheweb.com/blog/image-onload-isnt-being-called
//     var img = new Image();

//     img.onload = onload;
//     img.src = src;

//     return img;
// }



// function generateGlitchItem() {
//     let glitchCount = 0
//     glitchCount = parseInt(Math.random() * 3 + 3)
//     console.log('glitchCount = ' + glitchCount)
//     let randArr = []
//     let step = parseInt(100 / glitchCount)
//     console.log('step = ' + step)
//     for (let i = 0; i < glitchCount - 1; i++) {
//         let a = 0
//         if (randArr.length > 0)
//             a = parseInt(Math.random() * (100 - getRandSum()))
//         else
//             a = parseInt(Math.random() * step)
//         randArr.push(a)
//     }
//     randArr.push(100 - getRandSum())
//     console.log(randArr)

//     function getRandSum() {
//         let sum = 0
//         randArr.forEach(v => {
//             sum += v
//         })
//         return sum
//     }
//     var glitchItemStr = ''
//     for (let i = 0; i < glitchCount; i++) {
//         glitchItemStr += '<div class="glitch-item"'
//         glitchItemStr += ' style="height: ' + randArr[i] + '%;'
//         glitchItemStr += ' top: ' + getCurTop(i) + '%;'
//         glitchItemStr += ' background-image: url(' + finalUrl + ');'
//         glitchItemStr += ' background-position: 0 ' + getCurTop(i) + '%;'
//         glitchItemStr += '"'
//         glitchItemStr += '></div>'
//     }

//     function getCurTop(ind) {
//         let sum = 0
//         for (let i = 0; i < ind; i++) {
//             sum += randArr[i]
//         }
//         return sum
//     }

//     let glitchWrap = $id('glitchWrap');
//     glitchWrap.innerHTML = glitchItemStr

//     let checkSum = 0
//     for (let i = 0; i < randArr.length; i++) {
//         checkSum += randArr[i]
//     }
//     if (checkSum === 100)
//         console.log('glitch random array passed!')
// }