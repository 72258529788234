import {
    $id,
    $class,
    $classAll,
    $addClass,
    $removeClass,
    $emit,
    $hide,
    $show
} from '../util'
import anime from '../anime.min.js'

export default function () {
    let gifWrap = $id('gifWrap')
    let gifNavBtn = $id('gifNavBtn')
    let gifBlackMask = $class('gif-black-mask')
    let gifBtns = $classAll('gif-btn')
    let gifIndex = 0

    // gif nav button control
    gifNavBtn.ontouchend = function () {
        if (gifWrap.classList.contains('full')) {
            $removeClass(gifWrap, 'full')
            anime({
                targets: gifBlackMask,
                opacity: [1, 0],
                duration: 300
            })
        } else {
            $addClass(gifWrap, 'full')
            $show(gifBlackMask)
            anime({
                targets: gifBlackMask,
                opacity: [0, 1],
                duration: 300
            })
        }
    }

    let init = () => {
        // set all gif btns height
        gifBtns.forEach((elm) => {
            elm.style.height = elm.offsetWidth + 'px'
        })

        // gif btns click event
        gifBtns.forEach(function (btn) {
            btn.ontouchend = function () {
                gifBtns.forEach((elm) => {
                    elm.classList.remove('select');
                })
                btn.classList.add('select');

                gifIndex = btn.getAttribute('data-id');

                $emit('pattern-gif', gifIndex)
            }
        })
    }



    this.hide = (callback) => {
        $hide(gifBlackMask)
        $removeClass(gifWrap, 'full')
        anime({
            targets: gifWrap,
            opacity: [1, 0],
            duration: 300,
            easing: 'linear',
            complete: () => {
                if (callback) callback()
                $hide(gifWrap)
            }
        })
    }

    this.show = () => {
        $show(gifWrap)
        init()
        anime({
            targets: gifWrap,
            opacity: [0, 1],
            duration: 300
        })
    }
}