import {
    $id,
    $class,
    $classAll,
    $addClass,
    $removeClass,
    $swipeDetect,
    $emit,
    $show,
    $hide
} from '../util'
import anime from '../anime.min.js'

export default function () {
    let chooseWrap = $id('chooseWrap')
    let contents = $classAll('choose-slide-content')
    let items = $classAll('choose-slide-item')

    let wWidth = window.innerWidth
    let bigWidth = wWidth * 0.8
    let smallWidth = wWidth * 0.6

    let curInd = 1
    let lastInd = 1

    chooseWrap.style.height = wWidth + 'px'

    for (let i = 0; i < items.length; i++) {
        for (let i = 0; i < items.length; i++) {
            let nowW = smallWidth
            if (i === curInd) nowW = bigWidth
            let nowX = wWidth / 2 + smallWidth * 1.0 * (i - curInd)

            items[i].style.width = nowW + 'px'
            items[i].style.height = nowW + 'px'
            items[i].style.left = nowX + 'px'
        }
    }

    let lastSwipeTime = 0
    $swipeDetect(chooseWrap, function (swipedir) {
        if (Date.now() - lastSwipeTime < 2000) return false
        console.log(swipedir)
        if (swipedir === 'left') {
            if (curInd === contents.length - 1) return false
            hideSvg();
            setTimeout(() => {
                curInd++
                if (curInd >= contents.length) curInd = contents.length - 1
                setContents()
            }, 500);
            lastSwipeTime = Date.now()
        } else if (swipedir === 'right') {
            if (curInd === 0) return false
            hideSvg();
            setTimeout(() => {
                curInd--
                if (curInd <= 0) curInd = 0
                setContents()
            }, 500);
            lastSwipeTime = Date.now()
        } else if (swipedir === 'tap') {
            let toPage = ''
            // tap click
            switch (curInd) {
                case 0:
                    $emit('show-gif');
                    break;
                case 1:
                    $emit('show-name');
                    break;
                case 2:
                    $emit('show-diy');
                    break;
                default:
                    break;
            }
            // lastSwipeTime = Date.now()
        }

    })


    function setContents() {
        if (curInd === lastInd) return false

        lastInd = curInd

        for (let i = 0; i < items.length; i++) {
            let nowW = smallWidth
            if (i === curInd) nowW = bigWidth
            let nowX = wWidth / 2 + smallWidth * 1.0 * (i - curInd)
            anime({
                targets: items[i],
                left: nowX,
                width: nowW,
                height: nowW,
                duration: 350,
                easing: 'easeInOutCubic'
            })
        }

        // change choose wrap bg class
        $removeClass(chooseWrap, 'choose-bg-0')
        $removeClass(chooseWrap, 'choose-bg-1')
        $removeClass(chooseWrap, 'choose-bg-2')
        $addClass(chooseWrap, 'choose-bg-' + curInd)

        setTimeout(() => {
            showSvg();
        }, 350);
    }

    this.hide = (callback) => {
        hideSvg();
        anime({
            targets: chooseWrap,
            opacity: 0,
            duration: 500,
            easing: 'easeInOutCubic',
            complete: () => {
                if (callback) callback()
            }
        })
    }

    this.show = () => {
        $show(chooseWrap)
        anime({
            targets: chooseWrap,
            opacity: [0, 1],
            duration: 300,
            easing: 'linear',
            complete: () => {
                showSvg();
            }
        })

        lastSwipeTime = Date.now();
    }

    // test svg functions
    let svgWrap = document.querySelector('.svg-wrap');
    svgWrap.style.height = window.innerWidth * 0.8 + 'px'

    let svgLine1 = $class('svg-line-1')
    // init
    $addClass(svgWrap, 'hide')
    $addClass(svgLine1, 'hide')
    $addClass(svgWrap, 'svg-active')

    // test
    // setTimeout(() => {
    //     setTimeout(() => {
    //         hideSvg()
    //     }, 2000);
    //     showSvg();
    // }, 500);



    // dev
    function hideSvg() {
        $addClass(svgWrap, 'hide')
        $addClass(svgLine1, 'hide')

        // 内圈红色淡出
        anime({
            targets: '.svg-inner-circle-1',
            opacity: 0,
            scale: 1.05,
            easing: 'linear',
            duration: 500
        });

        // 背景两个圆圈淡入
        // anime({
        //     targets: '.svg-bg-circle',
        //     opacity: 1,
        //     easing: 'linear',
        //     duration: 300
        // });

        // 最外圈半透明出现
        anime({
            targets: '.svg-outer-circle-1 path',
            opacity: 0,
            scale: 0.8,
            easing: 'linear',
            duration: 300
        });

        // 外圈N个线条淡出旋转
        anime({
            targets: '.svg-outer-rect',
            rotate: [0, 40],
            opacity: 0,
            easing: 'linear',
            duration: 300
        })

        // 最外点圆淡入生长旋转
        anime({
            targets: '.svg-outside-dot-wrap',
            opacity: 0,
            easing: 'linear',
            duration: 500
        })

        // 最小原型动画
        anime({
            targets: '.svg-inner-circle-4',
            opacity: 0,
            easing: 'linear',
            duration: 100
        })

        // hide all choose circle texts
        anime({
            targets: '.choose-circle-text',
            opacity: 0,
            easing: 'linear',
            duration: 300
        });

        // hide all choose mode texts
        anime({
            targets: '.choose-mode-text',
            opacity: 0,
            easing: 'linear',
            duration: 300
        });

        // hide all choose center sprite animation
        anime({
            targets: '.choose-center-sprite',
            opacity: 0,
            easing: 'linear',
            duration: 300
        })
    }



    function showSvg() {
        // 背景文字淡入

        $removeClass(svgWrap, 'hide')
        // 最外圈半透明出现
        anime({
            targets: '.svg-outer-circle-1 path',
            opacity: [0, 1],
            scale: [0.8, 1],
            easing: 'linear',
            duration: 300
        });

        // 内圈红色淡入
        anime({
            targets: '.svg-inner-circle-1',
            opacity: [0, 1],
            scale: [1.05, 1],
            easing: 'linear',
            duration: 500
        });

        // 背景两个圆圈淡出
        // anime({
        //     targets: '.svg-bg-circle',
        //     opacity: [1, 0],
        //     easing: 'linear',
        //     duration: 300
        // });

        // 外圈N个线条淡入旋转
        anime({
            targets: '.svg-outer-rect',
            opacity: [0, 1],
            easing: 'linear',
            duration: 200,
            complete: () => {
                anime({
                    targets: '.svg-outer-rect',
                    rotate: [0, 45],
                    easing: 'linear',
                    duration: 300,
                    complete: () => {
                        anime({
                            targets: '.svg-outer-rect',
                            rotate: 0,
                            easing: 'linear',
                            duration: 300,
                            complete: () => {
                                $removeClass(svgLine1, 'hide')
                            }
                        })
                    }
                })
            }
        })

        // 最外点圆淡入生长旋转
        anime({
            targets: '.svg-outside-dot-wrap',
            opacity: [0, 1],
            easing: 'linear',
            duration: 800,
            complete: () => {
                anime({
                    targets: '.svg-outside-dot',
                    scale: [1, 1.05],
                    easing: 'linear',
                    loop: true,
                    direction: 'alternate',
                })

                anime({
                    targets: '.svg-outside-dot-wrap',
                    rotate: [0, 360],
                    easing: 'linear',
                    loop: true,
                    direction: 'alternate',
                    duration: 20000
                })
            }
        })

        // 最小圆形动画
        anime({
            targets: '.svg-inner-circle-4',
            opacity: [0, 1],
            easing: 'linear',
            duration: 200,
            delay: 500
        })

        // 内圈白色旋转
        anime({
            targets: '.svg-outer-circle-3',
            rotate: [180, 360],
            easing: 'linear',
            duration: 500
        });

        // show choose circle text
        let circleTextClass = '.choose-text-' + curInd;
        anime({
            targets: circleTextClass,
            opacity: 1,
            easing: 'linear',
            duration: 300,
            delay: 1000
        });

        // show choose mode text
        let circleModeText = '.choose-mode-' + curInd;
        anime({
            targets: circleModeText,
            opacity: 1,
            easing: 'linear',
            duration: 300,
            delay: 1000
        });

        // set choose pagi
        let pagiWrap = $class('pagi-wrap')
        $removeClass(pagiWrap, 'pagi-0')
        $removeClass(pagiWrap, 'pagi-1')
        $removeClass(pagiWrap, 'pagi-2')
        $addClass(pagiWrap, 'pagi-' + curInd)

        // show center sprite animation
        anime({
            targets: '.choose-sprite-' + curInd,
            opacity: 1,
            easing: 'linear',
            duration: 300
        })
    }


    // bottom dot animation
    let dotWrap = $class('choose-bottom .dot-wrap');
    let dotInd = 0;
    setInterval(() => {
        dotInd++;
        if (dotInd >= 3) dotInd = 0;
        // console.log(dotInd, dotWrap)
        $removeClass(dotWrap, 'dot-0');
        $removeClass(dotWrap, 'dot-1');
        $removeClass(dotWrap, 'dot-2');
        $addClass(dotWrap, 'dot-' + dotInd);
    }, 1000);

    // choose center sprite animation
    for (let i = 0; i < 3; i++) {
        let tmpImgs = $classAll('choose-sprite-' + i + ' img')
        let tmpInd = 0
        setInterval(() => {
            tmpImgs.forEach((elm) => {
                elm.style.display = 'none'
            })
            tmpImgs[tmpInd].style.display = 'block'
            tmpInd++
            if (tmpInd >= tmpImgs.length) tmpInd = 0
        }, 500)
    }
}


// function doSwipe() {

//     anime({
//         targets: scrollCoords,
//         x: sw * curInd,
//         duration: 350,
//         easing: 'easeInOutCubic',
//         update: () => {
//             chooseWrap.scrollTo(scrollCoords.x, 0)
//         },
//     })
// }