import {
    patternStore
} from './patternStore'
import {
    $id
} from './util.js'

export default function () {

    // valide light index
    var validIndArr = [
        //  1,  2,  3,  4,  5,  6,  7,  8,  9, 10, 11, 12, 13, 14, 15, 16
        /*1*/
        0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0,
        /*2*/
        0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0,
        /*3*/
        0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0,
        /*4*/
        0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0,
        /*5*/
        0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0,
        /*6*/
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        /*7*/
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        /*8*/
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        /*9*/
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        /*10*/
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        /*11*/
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        /*12*/
        0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0,
        /*13*/
        0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0,
        /*14*/
        0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0,
        /*15*/
        0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0,
        /*16*/
        0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0
    ];
    // touch for control light switch
    let bCanTouch = false;
    let wrapDom = null;
    var self = this;
    let wrapSize = 0;
    let lightSize = 0;
    let lightDoms = null;

    let selectArr = new Array(validIndArr.length);
    selectArr.fill(0)

    let lastMoveInd = 0;
    let circleWrapZoom = 0.8;

    let lightLifeArr = [];
    lightLifeArr = validIndArr.slice();
    lightLifeArr.fill(1);

    let animateInd = 0;
    let animateFps = 1;
    let animateTimer = null;
    let animatePatternArr = [];
    let moveMargin = 4;
    let moveCount = moveMargin;

    let lightsHistoryArr = [];

    this.create = function (id) {
        wrapDom = $id(id);
        // set dom to rectangle
        console.log(wrapDom.offsetWidth)
        wrapSize = wrapDom.offsetWidth;
        console.log(wrapSize)
        wrapDom.style.height = wrapSize + 'px';
        lightSize = wrapSize / 16;
        generateLights();
    }

    this.enableTouch = () => {
        bCanTouch = true;
    }

    this.disableTouch = () => {
        bCanTouch = false;
    }

    this.stop = function () {
        if (animateTimer) clearTimeout(animateTimer);
        selectArr.fill(0);
        renderLights(getPattern(' '));
    }

    this.getCompressedArray = function () {
        return lightArrToCompressArray(selectArr);
    }

    this.getGifCompressedArray = function () {
        // animatePatternArr
        let gifArr = animatePatternArr;
        var fArr = [];
        for (let i = 0; i < gifArr.length; i++) {
            fArr = fArr.concat(lightArrToCompressArray(gifArr[i]));
        }
        return fArr;
    }

    this.printCurArr = function () {
        console.log(selectArr.toString())
    }

    this.clearPattern = function () {
        console.log('[light control] clear pattern');
        selectArr.fill(0);
        renderLights(selectArr);
    }

    this.playGif = function (gifIndex) {
        gifIndex = parseInt(gifIndex);
        if (gifIndex < 0 || gifIndex > 25) return false;
        console.log('[light control] play gif index = ' + gifIndex);
        gifAnimateStart(gifDB[gifIndex])
        // switch(gifIndex) {
        //     case 0: gifAnimateStart(gifHeart); break;
        //     case 1: gifAnimateStart(gifSmile); break;
        //     default: break;
        // }
    }



    //
    let curArr = getPattern(' ');

    function checkIndex(ind) {
        if (validIndArr[ind] === 1) return true;
        else return false;
    }

    function getPattern(val) {
        if (val in patternStore) return patternStore[val].slice();
        else return patternStore[' '].slice();
    }

    this.playName = function (name) {
        let nameArr = name.split('');
        let patternArr = [];
        for (let i = 0; i < nameArr.length; i++) {
            patternArr.push(getPattern(nameArr[i]));
        }
        nameAnimateStart(patternArr);
    }

    this.undo = () => {
        console.log('[light control] undo')
        if (lightsHistoryArr.length > 0) {
            // console.log(lightsHistoryArr)
            lightsHistoryArr.splice(lightsHistoryArr.length - 1, 1);
            if (lightsHistoryArr.length === 0) {
                selectArr.fill(0);
                renderLights(selectArr)
            } else {
                renderLights(lightsHistoryArr[lightsHistoryArr.length - 1]);
                selectArr = lightsHistoryArr[lightsHistoryArr.length - 1].slice(0);
            }
            console.log(lightsHistoryArr)
        }
    }


    function nameAnimateStart(patternArr) {
        animateInd = 0;
        animatePatternArr = patternArr;
        curArr = animatePatternArr[animateInd];
        selectArr.fill(0);
        moveCount = moveMargin;
        animateFps = 30;

        nameAnimateFun();
    }



    // name pattern animation function
    function nameAnimateFun() {

        selectArr.fill(0);
        for (let i = 0; i < curArr.length; i++) {

            let x = i % 16;
            if (x > moveCount) continue;

            let sx = 16 - moveCount + x;
            if (sx >= 16 || sx < 0) continue;

            let y = parseInt(i / 16);
            let newInd = sx + y * 16;
            if (!checkIndex(newInd)) continue;

            selectArr[newInd] = curArr[i];
        }

        // console.log(curArr.toString())
        moveCount++;

        if (moveCount >= 16 + moveMargin * 3) {
            // console.log('===================================')
            moveCount = moveMargin;
            animateInd++;
            if (animateInd >= animatePatternArr.length) animateInd = 0;

            curArr = animatePatternArr[animateInd];
        }

        renderLights(selectArr);

        if (animateTimer) clearTimeout(animateTimer);
        animateTimer = setTimeout(nameAnimateFun, parseInt(1000 / animateFps));
    }




    function generateLights() {
        var i = 0;
        var htmlStr = '';
        htmlStr += '<div class="a-center" style="';
        htmlStr += 'width: ' + wrapSize * circleWrapZoom + 'px; ';
        htmlStr += 'height: ' + wrapSize * circleWrapZoom + 'px;">';
        for (i = 0; i < validIndArr.length; i++) {
            htmlStr += '<span class="light-item ';
            if (checkIndex(i)) htmlStr += 'unselect';
            htmlStr += '" data-id="' + i + '">';
            // if (checkIndex(i)) htmlStr += '<i></i>'
            htmlStr += '</span>';
        }
        htmlStr += '</div>'
        wrapDom.innerHTML = htmlStr;
        lightDoms = wrapDom.querySelectorAll('span');
        wrapDom.ontouchend = function () {
            lightLifeArr.fill(1);
            console.log('[light control] reset light life');
        }
        for (i = 0; i < lightDoms.length; i++) {
            if (!bCanTouch) return false;
            (function (i) {
                // console.log(i)
                lightDoms[i].addEventListener('touchmove', function (e) {
                    // console.log(e)
                    let t = e.touches[0];
                    // console.log(t)
                    let tdom = document.elementFromPoint(t.clientX, t.clientY);
                    let lightIndex = tdom.getAttribute('data-id');
                    if (lightIndex != lastMoveInd && lightIndex < validIndArr.length) {
                        // console.log(lightIndex, lastMoveInd)
                        if (checkIndex(lightIndex) && lightLifeArr[lightIndex] === 1) {
                            selectArr[lightIndex] = selectArr[lightIndex] === 1 ? 0 : 1;
                            lightLifeArr[lightIndex] = 0;
                        }
                        renderLights(selectArr);
                        lastMoveInd = lightIndex;
                        lightsHistoryArr[lightsHistoryArr.length - 1] = selectArr.slice(0);
                        // console.log(lightsHistoryArr.toString())
                    }
                });

                lightDoms[i].addEventListener('touchstart', function (e) {
                    if (e.touches && e.touches.length === 1) {
                        var target = e.touches[0].target;
                        var lightIndex = target.getAttribute('data-id');
                        if (checkIndex(lightIndex) && lightLifeArr[lightIndex] === 1) {
                            // if (checkIndex(lightIndex)) {
                            selectArr[lightIndex] = selectArr[lightIndex] === 1 ? 0 : 1;
                            lightLifeArr[lightIndex] = 0;
                        }
                        lightsHistoryArr.push(selectArr.slice())
                        renderLights(selectArr);
                        console.log(lightsHistoryArr)
                    }
                });
            })(i);
        }
    }

    function lightArrToCompressArray(lightArr) {
        var tmpArr = [];
        var ind = 0;
        for (var i = 0; i < 32; i++) {
            tmpArr.push([])
            for (var j = 0; j < 8; j++) {
                tmpArr[i].push(lightArr[ind])
                ind++;
            }
        }

        var fArr = []
        for (var i = 0; i < tmpArr.length; i++) {
            var num = 0;
            for (var j = 0; j < tmpArr[i].length; j++) {
                if (tmpArr[i][j] === 1) {
                    num += Math.pow(2, j)
                }
            }
            fArr.push(num)

        }
        return fArr;
    }



    function renderLights(lightArray) {
        for (let i = 0; i < lightDoms.length; i++) {
            var lightIndex = lightDoms[i].getAttribute('data-id');
            if (lightArray[lightIndex] === 1) {
                lightDoms[i].classList.remove('unselect');
                lightDoms[i].classList.add('select');
            } else if (checkIndex(lightIndex)) {
                lightDoms[i].classList.remove('select');
                lightDoms[i].classList.add('unselect');
            }
        }
    }


    let gifDB = [];
    // 0: heart
    gifDB.push([]);
    gifDB[0].push(getPattern('heart'));
    gifDB[0].push(getPattern(' '));

    // 1: smile
    gifDB.push([]);
    gifDB[1].push(getPattern('smile-1'));
    gifDB[1].push(getPattern('smile-2'));

    // 2: pacman
    gifDB.push([]);
    gifDB[2].push(getPattern('pacman-1'));
    gifDB[2].push(getPattern('pacman-2'));

    // 3: dabian
    gifDB.push([]);
    gifDB[3].push(getPattern('dabian-1'));
    gifDB[3].push(getPattern('dabian-2'));

    // 4: zombi
    gifDB.push([]);
    gifDB[4].push(getPattern('zombi-1'));
    gifDB[4].push(getPattern('zombi-2'));

    // 5: whala
    gifDB.push([]);
    gifDB[5].push(getPattern('whale-1'));
    gifDB[5].push(getPattern('whale-2'));

    // 6: snails
    gifDB.push([]);
    gifDB[6].push(getPattern('snails-1'));
    gifDB[6].push(getPattern('snails-2'));
    gifDB[6].push(getPattern('snails-3'));

    // 7: spider
    gifDB.push([]);
    gifDB[7].push(getPattern('spider-1'));
    gifDB[7].push(getPattern('spider-2'));

    // 8: snow
    gifDB.push([]);
    gifDB[8].push(getPattern('snow-1'));
    gifDB[8].push(getPattern('snow-2'));
    gifDB[8].push(getPattern('snow-3'));

    // 9: ginger
    gifDB.push([]);
    gifDB[9].push(getPattern('ginger-1'));
    gifDB[9].push(getPattern('ginger-2'));

    // 10: pig
    gifDB.push([]);
    gifDB[10].push(getPattern('pig-1'));
    gifDB[10].push(getPattern('pig-2'));
    gifDB[10].push(getPattern('pig-3'));

    // 11: fruit
    gifDB.push([]);
    gifDB[11].push(getPattern('fruit-1'));
    gifDB[11].push(getPattern('fruit-2'));
    gifDB[11].push(getPattern('fruit-3'));

    // 12: beer
    gifDB.push([]);
    gifDB[12].push(getPattern('beer-1'));
    // gifDB[12].push(getPattern('beer-2'));
    gifDB[12].push(getPattern('beer-3'));
    // gifDB[12].push(getPattern('beer-4'));
    gifDB[12].push(getPattern('beer-5'));

    // 13: animal
    gifDB.push([]);
    gifDB[13].push(getPattern('animal-1'));
    gifDB[13].push(getPattern('animal-2'));
    gifDB[13].push(getPattern('animal-3'));

    // 14: taiji
    gifDB.push([]);
    gifDB[14].push(getPattern('taiji-1'));
    gifDB[14].push(getPattern('taiji-2'));
    gifDB[14].push(getPattern('taiji-3'));
    gifDB[14].push(getPattern('taiji-4'));

    // 15: mushroom
    gifDB.push([]);
    gifDB[15].push(getPattern('mushroom-1'));
    gifDB[15].push(getPattern('mushroom-2'));
    gifDB[15].push(getPattern('mushroom-3'));
    gifDB[15].push(getPattern('mushroom-4'));

    // 16: fire
    gifDB.push([]);
    gifDB[16].push(getPattern('fire-1'));
    gifDB[16].push(getPattern('fire-2'));
    gifDB[16].push(getPattern('fire-3'));
    gifDB[16].push(getPattern('fire-4'));


    // 17: emoji1
    gifDB.push([]);
    gifDB[17].push(getPattern('emoji1-1'));
    gifDB[17].push(getPattern('emoji1-2'));
    gifDB[17].push(getPattern('emoji1-3'));


    // 18: emoji2
    gifDB.push([]);
    gifDB[18].push(getPattern('emoji2-1'));
    gifDB[18].push(getPattern('emoji2-2'));

    function gifAnimateStart(gifArr) {
        animateInd = 0;
        animatePatternArr = gifArr;
        selectArr.fill(0);
        animateFps = 3;

        gifAnimateFun();
    }

    // gif pattern animation function
    function gifAnimateFun() {

        selectArr.fill(0);
        selectArr = animatePatternArr[animateInd].slice();

        animateInd++;

        if (animateInd >= animatePatternArr.length) {
            animateInd = 0;
        }

        renderLights(selectArr);

        if (animateTimer) clearTimeout(animateTimer);
        animateTimer = setTimeout(gifAnimateFun, parseInt(1000 / animateFps));
    }

}