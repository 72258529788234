import { $id, $class, $addClass, $removeClass, $emit, $show } from '../util'
import anime from '../anime.min.js'
export default function() {
    let homeBtn = $class('home-btn')
    let enterBtn = $class('enter-btn')
    let btnWrap = $class('control-btn-wrap')

    homeBtn.ontouchstart = () => {
        $addClass(homeBtn, 'active')
    }
    homeBtn.ontouchend = () => {
        $removeClass(homeBtn, 'active')
        $emit('button-home')        
    }

    enterBtn.ontouchstart = () => {
        $addClass(enterBtn, 'active')
    }
    enterBtn.ontouchend = () => {
        $removeClass(enterBtn, 'active')
        $emit('button-enter')
        $emit('do-send-data')
        // $emit('send-success')
    }
    

    this.show = () => {
        $show(btnWrap)
        anime({
            targets: homeBtn,
            translateX: ['-120%', 0],
            duration: 300,
            easing: 'linear'
        })

        anime({
            targets: enterBtn,
            translateX: ['120%', 0],
            duration: 300,
            easing: 'linear'
        })
    }

    this.hide = () => {
        anime({
            targets: homeBtn,
            translateX: '-120%',
            duration: 300,
            easing: 'linear'
        })

        anime({
            targets: enterBtn,
            translateX: '120%',
            duration: 300,
            easing: 'linear'
        })
    }

}