function $id(id) {
    return document.getElementById(id);
}

function $class(classname) {
    return document.querySelector('.' + classname);
}

function $classAll(classname) {
    return document.querySelectorAll('.' + classname);
}


function $addClass(dom, classname) {
    dom.classList.add(classname)
}

function $removeClass(dom, classname) {
    dom.classList.remove(classname)
}

function $checkId(id) {
    let r = /^[0-9]{4}$/g;
    let _id = parseInt(id);
    if (r.test(_id) && _id >= 1000 && _id <= 9999) {
        return true;
    } else {
        return false;
    }
}

function $checkName(name) {
    let r = /^[a-z0-9A-Z\s]+$/g;
    return r.test(name);
}

function $on(eventName, callback) {
    window.addEventListener(eventName, function (e) {
        callback(e.detail);
    })
}

function $emit(eventName, eventData) {
    let evt = new CustomEvent(eventName, {
        detail: eventData
    });
    window.dispatchEvent(evt);
}

function $uuid() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

// type -> 'POST', 'GET'
// path -> '/api'
function $ajax(type, path, formData, successFun, errorFun, accessToken) {
    let xhr = new XMLHttpRequest();
    // xhr.open(type, 'http://127.0.0.1:13199' + path, true);
    xhr.open(type, 'https://badge.btech.cc' + path, true);
    if (accessToken) {
        xhr.setRequestHeader('Access-Token', accessToken);
    }
    // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    // xhr.withCredentials = true;
    // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.onload = function () {
        if (xhr.status === 200) {
            successFun(xhr.responseText);
        } else {
            errorFun(xhr);
        }
    };
    xhr.onerror = function() {
        errorFun(xhr)
    }
    // let params = 'uuid=1232'
    // xhr.send(params);
    xhr.send(formData);
}

// http://www.javascriptkit.com/javatutors/touchevents2.shtml
function $swipeDetect(el, callback){
  
    var touchsurface = el,
    swipedir,
    startX,
    startY,
    distX,
    distY,
    threshold = 50, //required min distance traveled to be considered swipe
    restraint = 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime = 500, // maximum time allowed to travel that distance
    elapsedTime,
    startTime,
    handleswipe = callback || function(swipedir){}
  
    touchsurface.addEventListener('touchstart', function(e){
        var touchobj = e.changedTouches[0]
        swipedir = 'none'
        distX = 0
        distY = 0
        startX = touchobj.pageX
        startY = touchobj.pageY
        startTime = new Date().getTime() // record time when finger first makes contact with surface
        e.preventDefault()
    }, false)
  
    touchsurface.addEventListener('touchmove', function(e){
        e.preventDefault() // prevent scrolling when inside DIV
    }, false)
  
    touchsurface.addEventListener('touchend', function(e){
        var touchobj = e.changedTouches[0]
        distX = touchobj.pageX - startX // get horizontal dist traveled by finger while in contact with surface
        distY = touchobj.pageY - startY // get vertical dist traveled by finger while in contact with surface
        elapsedTime = new Date().getTime() - startTime // get time elapsed
        if (elapsedTime <= allowedTime){ // first condition for awipe met
            if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint){ // 2nd condition for horizontal swipe met
                swipedir = (distX < 0)? 'left' : 'right' // if dist traveled is negative, it indicates left swipe
            }
            else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint){ // 2nd condition for vertical swipe met
                swipedir = (distY < 0)? 'up' : 'down' // if dist traveled is negative, it indicates up swipe
            }
            else if (Math.abs(distY) < 10 && Math.abs(distX) < 10) {
                swipedir = 'tap'
            }
        }
        console.log(Math.abs(distX), Math.abs(distY))
        console.log(elapsedTime, allowedTime)
        handleswipe(swipedir)
        e.preventDefault()
    }, false)
}
  
//USAGE:
/*
var el = document.getElementById('someel')
swipedetect(el, function(swipedir){
    swipedir contains either "none", "left", "right", "top", or "down"
    if (swipedir =='left')
        alert('You just swiped left!')
})
*/

function $show(dom) {
    dom.style.display = 'block'
}

function $hide(dom) {
    dom.style.display = 'none'
}

export {
    $id,
    $checkId,
    $checkName,
    $on,
    $emit,
    $uuid,
    $ajax,
    $addClass,
    $removeClass,
    $class,
    $classAll,
    $swipeDetect,
    $hide,
    $show
}