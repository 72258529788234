import {
    $id,
    $checkId,
    $on,
    $emit,
    $uuid,
    $ajax
} from './util.js'
import TopWrap from './components/TopWrap'
import BindWrap from './components/BindWrap'
import ChooseWrap from './components/ChooseWrap'
import ControlBtnWrap from './components/ControlBtnWrap'
import PatternWrap from './components/PatternWrap'
import GifWrap from './components/GifWrap'
import NameWrap from './components/NameWrap'
import DiyWrap from './components/DiyWrap'
import Notify from './components/Notify'

import TestPage from './components/testPage'

function GetQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return (r[2]);
    return null;
};

window.onload = function() {

    document.addEventListener('touchstart', function(e) {
        if (e.target.tagName !== 'INPUT') {
            console.log('do blur')
            window.scrollTo(0, 0)
            let inputs = document.getElementsByTagName('input')
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].blur()
            }
        }
    })

    let openid = GetQueryString('openid');

    let topWrap = new TopWrap()
    topWrap.doBind()

    let bindWrap = new BindWrap()

    let curPage = ''

    // var vConsole = new VConsole();
    // console.log('Hello world');

    let chooseWrap = new ChooseWrap()

    let controlBtnWrap = new ControlBtnWrap()

    let patternWrap = new PatternWrap()

    let gifWrap = new GifWrap()

    let nameWrap = new NameWrap()

    let diyWrap = new DiyWrap()

    let notify = new Notify()

    let testPage = new TestPage();

    const bDrawingGif = false

    // debug for diy page
    if (bDrawingGif) {
        curPage = 'DIY'
        chooseWrap.hide()
        patternWrap.show()
        $emit('pattern-enable-touch')
        controlBtnWrap.show()
        patternWrap.init(true)
        gifWrap.hide()
        bindWrap.hide()
        topWrap.hide()
        nameWrap.hide()
        diyWrap.show()
        $emit('pattern-enable-touch')
    }


    // controlBtnWrap.hide()
    // patternWrap.hide()
    // nameWrap.hide()
    // bindWrap.hide()
    // topWrap.hide()
    // chooseWrap.hide()

    document.body.addEventListener('touchmove', function(e) {
        // e.preventDefault();
        let cn = e.target.classList;
        let bDisable = true;
        cn.forEach(e => {
            if (e === 'gif-btn') {
                bDisable = false;
            }
        });

        if (bDisable) {
            e.preventDefault();
        }
    }, {
        passive: false
    })

    // back to home page
    $on('button-home', () => {
        gifWrap.hide(function() {
            chooseWrap.show()
            topWrap.doChoose()
        })
        patternWrap.hide()
        controlBtnWrap.hide()
        nameWrap.hide()
        diyWrap.hide()

        $emit('pattern-stop')
    })

    // click bind device id button
    $on('bind-done', (idVal) => {
        topWrap.doChoose(idVal)
        bindWrap.hide(() => {
            chooseWrap.show()
        })
    })


    // name page
    $on('show-name', () => {
        curPage = 'NAME'
        topWrap.hide()
        chooseWrap.hide(() => {
            nameWrap.show()
            controlBtnWrap.show()

            $emit('pattern-stop')
            $emit('pattern-disable-touch')
            patternWrap.show()
            patternWrap.init()
        })
    })

    // gif page
    $on('show-gif', () => {
        curPage = 'GIF'
        topWrap.hide()
        chooseWrap.hide(() => {
            gifWrap.show()
            controlBtnWrap.show()

            $emit('pattern-stop')
            $emit('pattern-disable-touch')
            patternWrap.show()
            patternWrap.init()
            $emit('pattern-gif', 0)
        })
    })

    // diy page
    $on('show-diy', () => {
        curPage = 'DIY'
        topWrap.hide()
        chooseWrap.hide(() => {
            controlBtnWrap.show()

            $emit('pattern-stop')
            $emit('pattern-enable-touch')
            patternWrap.show()
            patternWrap.init(true)

            diyWrap.show()
        })
    })
    let lastInputName = ''
    let gifSendTimer = null
    let gifSendCount = 0

    function gifSendFun() {
        // do send GIF pattern
        let dataStr = '2,' + patternWrap.getGifCompressedArray().toString();
        $emit('mqtt-send-data', {
            dataStr: dataStr
        })
        console.log('send gif data done')
        gifSendCount++;
        if (gifSendCount < 3) {
            if (gifSendTimer) clearTimeout(gifSendTimer)
            setTimeout(() => {
                gifSendFun()
            }, 500);
        }
    }
    $on('pattern-name', (name) => {
        console.log('get name is ' + name);
        lastInputName = name
    })
    $on('do-send-data', () => {
        if (curPage === 'NAME') {
            // do send name pattern
            let dataStr = '1,' + lastInputName;
            $emit('mqtt-send-data', {
                dataStr: dataStr
            })
        } else if (curPage === 'DIY') {
            // do send DIY pattern
            console.log('click do-send-data, DIY')
            if (!bDrawingGif) {
                let dataStr = '3,' + patternWrap.getCompressedArray().toString();
                $emit('mqtt-send-data', {
                    dataStr: dataStr
                })
            } else {
                patternWrap.printCurArr()
            }

        } else if (curPage === 'GIF') {
            if (gifSendTimer) clearTimeout(gifSendTimer)
            gifSendCount = 0
            gifSendFun()
        }
    })



    let deviceUUID = '';
    let accessToken = '';
    let curDeviceIdNum = 0;

    function doConnectDevice(deviceId) {
        curDeviceIdNum = deviceId;
        console.log('[do connect device] deviceid = ' + deviceId)

        // for debug
        // $emit('mqtt-connect-success');
        // return false;

        let fd = new FormData();
        fd.append('id', deviceId);
        $ajax('POST', '/getuuid', fd, function(data) {
            console.log(data);
            let d = JSON.parse(data);
            deviceUUID = d.uuid;
            accessToken = d.token;
            if (deviceUUID.length > 0 && accessToken) {
                // $emit('mqtt-connect-success');
                $emit('bind-done', deviceId)

                // send device to wechat user
                if (openid) {
                    let msgFd = new FormData();
                    msgFd.append('openid', openid);
                    msgFd.append('device_id', deviceId);
                    $ajax('POST', '/sendmessage', msgFd, function(data) {
                        console.log('sendmessage done')
                        console.log(data);
                    })
                }

                $emit('connect-success');
            } else {
                $emit('connect-failed');
            }
        }, function(xhr) {
            console.log(xhr)
        })
    }

    function doSendDataToDevice(dataStr) {
        // client.publish('/badge/data/' + deviceUUID, dataStr)

        let fd = new FormData();
        fd.append('uuid', deviceUUID);
        fd.append('data', dataStr);
        $ajax('POST', '/pub', fd, function(data) {
            console.log(data);
            let d = JSON.parse(data);
            if (d.status === 200) {
                $emit('send-success');
            } else {
                $emit('send-failed');
            }
        }, function(xhr) {
            console.log(xhr)
            $emit('send-failed');
        }, accessToken)
    }

    $on('mqtt-connect', function(data) {
        console.log('[mqtt-connect] data => ' + data.deviceId);
        doConnectDevice(data.deviceId);
    })

    $on('mqtt-send-data', function(data) {
        console.log('[mqtt-send-data] send data to device');
        // console.log('[mqtt-send-data] data => ' + data.dataStr);
        doSendDataToDevice(data.dataStr);
    })

    // fix can not scroll back when input blur
    var u = navigator.userAgent,
        app = navigator.appVersion
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    let inputs = document.getElementsByTagName('input')
    for (let i = 0; i < inputs.length; i++) {
        inputs[i].onblur = function() {
            console.log('blur')
            if (isIOS) {
                blurAdjust()
                    // alert("1231321233")
            }
        }
    }
    // 解决苹果不回弹页面
    function blurAdjust(e) {
        setTimeout(() => {
            // alert("1231321233")
            if (document.activeElement.tagName == 'INPUT' || document.activeElement.tagName == 'TEXTAREA') {
                return
            }
            let result = 'pc';
            if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
                result = 'ios'
            } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
                result = 'android'
            }

            if (result = 'ios') {
                document.activeElement.scrollIntoViewIfNeeded(true);
            }
        }, 100)
    }
}