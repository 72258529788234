import {
    $id,
    $class,
    $addClass,
    $removeClass,
    $hide,
    $show,
    $checkName,
    $emit
} from '../util'
import LightControl from '../lightControl.js'
import anime from '../anime.min.js'

export default function () {

    let diyWrap = $id('diyWrap')
    let delBtn = $class('delete-btn')
    let undoBtn = $class('undo-btn')

    undoBtn.ontouchstart = () => {
        console.log('undo button')
        $addClass(undoBtn, 'active')
    }
    undoBtn.ontouchend = () => {
        $removeClass(undoBtn, 'active')
        $emit('pattern-undo')
    }

    delBtn.ontouchstart = () => {
        console.log('delete button')
        $addClass(delBtn, 'active')
    }
    delBtn.ontouchend = () => {
        $removeClass(delBtn, 'active')
        $emit('pattern-clear')
    }

    

    this.show = () => {
        $show(diyWrap)
        anime({
            targets: diyWrap,
            translateY: ['200%', '0'],
            duration: 300
        })
    }

    this.hide = () => {
        anime({
            targets: diyWrap,
            translateY: ['0', '200%'],
            duration: 300,
            complete: () => {
                $hide(diyWrap)
            }
        })
    }

}