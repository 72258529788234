import { $id, $addClass, $removeClass, $show, $hide} from '../util'
import anime from '../anime.min.js'

export default function() {
    let topWrap = $id('topWrap');
    let logoIcon = $id('logoIcon');
    let logoText = $id('logoText');
    let idWrap = $id('idWrap');
    let idValue = $id('idValue');
    
    this.doBind = () => {
        $show(topWrap)
        $hide(idWrap)
        $show(logoIcon)
        $show(logoText)
        
        $removeClass(logoIcon, 'topleft')
        $addClass(logoIcon, 'center')

        // top wrap fade animation
        anime({
            targets: topWrap,
            opacity: [0, 1],
            loop: false,
            duration: 800,
            easing: 'linear' 
        });
    }

    this.doChoose = (idVal) => {
        if (idVal) idValue.innerHTML = idVal
        $show(topWrap)
        $show(idWrap)
        $show(logoText)
        $show(logoIcon)

        $removeClass(logoIcon, 'center')
        $addClass(logoIcon, 'topleft')

        anime({
            targets: idWrap,
            opacity: [0, 1],
            scale: [0, 1],
            duration: 300
        })
        anime({
            targets: logoIcon,
            opacity: [0, 1],
            duration: 300
        });
    }

    this.hide = () => {
        anime({
            targets: idWrap,
            opacity: [1, 0],
            duration: 300,
            complete: () => {
                $hide(topWrap)
            }
        })
    }

    this.show = () => {
        
    }

}