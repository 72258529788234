import {
    $id,
    $on,
    $show,
    $hide
} from '../util.js'

import anime from '../anime.min.js'

export default function () {
    let dom = $id('');
    let textDom = $id('notifyText');
    let timer = null;
    let dialogSendSuccess = $id('dialogSendSuccess');
    let dialogSendFailed = $id('dialogSendFailed');
    let dialogConnectSuccess = $id('dialogConnectSuccess');
    let dialogConnectFailed = $id('dialogConnectFailed');
    let dialogDeviceIdError= $id('dialogDeviceIdError');

    $on('send-success', () => {
        $show(dialogSendSuccess);
        anime({
            targets: dialogSendSuccess,
            opacity: [0, 1],
            duration: 300,
            easing: 'linear',
            complete: () => {
                setTimeout(() => {
                    $hide(dialogSendSuccess);
                }, 500);
            }
        })
    })

    $on('send-failed', () => {
        $show(dialogSendFailed);
        anime({
            targets: dialogSendFailed,
            opacity: [0, 1],
            duration: 300,
            easing: 'linear',
            complete: () => {
                setTimeout(() => {
                    $hide(dialogSendFailed);
                }, 500);
            }
        })
    })

    $on('connect-success', () => {
        $show(dialogConnectSuccess);
        anime({
            targets: dialogConnectSuccess,
            opacity: [0, 1],
            duration: 300,
            easing: 'linear',
            complete: () => {
                setTimeout(() => {
                    $hide(dialogConnectSuccess);
                }, 500);
            }
        })
    })

    $on('connect-failed', () => {
        $show(dialogConnectFailed);
        anime({
            targets: dialogConnectFailed,
            opacity: [0, 1],
            duration: 300,
            easing: 'linear',
            complete: () => {
                setTimeout(() => {
                    $hide(dialogConnectFailed);
                }, 500);
            }
        })
    })

    $on('device-id-error', () => {
        $show(dialogDeviceIdError);
        anime({
            targets: dialogDeviceIdError,
            opacity: [0, 1],
            duration: 300,
            easing: 'linear',
            complete: () => {
                setTimeout(() => {
                    $hide(dialogDeviceIdError);
                }, 500);
            }
        })
    })

    this.error = function (text) {
        textDom.innerHTML = text;
        dom.classList.add('error');
        dom.classList.add('show');
        delayHide();
    }

    this.success = function (text) {
        textDom.innerHTML = text;
        dom.classList.add('success');
        dom.classList.add('show');
        delayHide();
    }

    function delayHide() {
        if (timer) clearTimeout(timer);
        timer = setTimeout(function () {
            dom.classList.remove('show');
            dom.classList.remove('success');
            dom.classList.remove('error');
        }, 1500);
    }
}