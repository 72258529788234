import {
    $id,
    $class,
    $addClass,
    $removeClass,
    $emit,
    $show,
    $hide,
    $checkId
} from '../util'
import anime from '../anime.min.js'

export default function () {
    let bindWrap = $id('bindWrap');
    let titleWrap = $class('title-wrap')
    let inputWrap = $class('input-wrap')
    let inputBg = $class('input-bg-wrap')
    let dotWrap = $class('dot-wrap')
    let bindBtnWrap = $id('bindBtnWrap')
    let bindIdInput = $id('bindIdInput')
    let dots = document.querySelectorAll('.dot-wrap .dot')

    let btnOuter = $class('btn-wrap .btn-outer')
    let btnBorder = $class('btn-wrap .btn-border')
    let btnRed = $class('btn-wrap .btn-red')
    let btnDot = $class('btn-wrap .btn-dot')
    console.log('dots => ')
    console.log(dots)

    // console.log(inputBg)
    // fix width for animation
    inputBg.style.width = inputBg.offsetWidth + 'px'

    let inputAnime = null
    let titleAnime = null
    let btnAnime = null

    play()
    function play() {

        // input wrap animation
        anime({
            targets: inputWrap,
            width: ['1%', '100%'],
            duration: 400,
            loop: false,
            easing: 'linear'
        });

        // title wrap animation
        anime({
            targets: titleWrap,
            scale: [0, 1],
            duration: 1000,
            loop: false,
            easing: 'linear'
        });

        // button outer and border animation
        btnAnime = anime({
            targets: [btnOuter, btnBorder],
            scale: [0, 1],
            duration: 1000,
            loop: false,
            easing: 'spring(1, 90, 90, 15)',
            complete: function (anim) {
                anime({
                    targets: [btnOuter, btnDot],
                    scale: 1.05,
                    duration: 700,
                    direction: 'alternate',
                    loop: true,
                    easing: 'linear'
                });
            }
        });

        anime({
            targets: btnRed,
            scale: [0, 1],
            loop: false,
            easing: 'spring(2, 70, 15, 5)'
        });
    }

    bindBtnWrap.ontouchend = function() {
        let idNum = bindIdInput.value;
        if ($checkId(idNum)) {
            console.log('valide id = ' + idNum);

            $emit('mqtt-connect', {
                deviceId: idNum
            })

            bindIdInput.blur();
        } else {
            bindIdInput.value = '';
            $emit('device-id-error');
            // notify.error('请输入正确的设备编号');
        }
        // console.log(bindIdInput.value)
    }

    let self = this
    this.hide = (callback) => {
        anime.remove([btnOuter, btnBorder])

        anime({
            targets: [inputWrap, bindBtnWrap, titleWrap],
            scale: 0,
            duration: 300,
            loop: false,
            easing: 'linear',
            complete: (anim) => {
                $hide(inputWrap)
                $hide(bindBtnWrap)
                $hide(titleWrap)
                if (callback) callback()
            }
        });
    }

}